import {
    Box,
    Button,
    Center,
    Column,
    Row,
    Text,
} from 'native-base';
import {StyleSheet, TouchableOpacity} from 'react-native';
import IcWAMP from '@ui/svg/IcWAMP';
import {ImageUris} from '@shared/models/image';
import React from 'react';
import WAMPImage from '@ui/components/image/WAMPImage';
import {useDashboardAssets} from '@ui/features/clubhouse/hooks/dashboard-hooks';
import {useTranslation} from 'react-i18next';

interface LoginScreenProps {
    onOpenRegister: () => void;
    onOpenLogin: () => void;
    image?: ImageUris;
    text?: string;
}

const LoginScreen = (props: LoginScreenProps) => {
    const {onOpenRegister, onOpenLogin} = props;
    const [dashboardConfig] = useDashboardAssets();
    const {t} = useTranslation();

    const image = props.image ?? dashboardConfig?.login?.base?.image;
    const text = props.text ?? dashboardConfig?.login?.base?.title?.en;

    return (
        <Center
            w='full'
            flex={1}
            h='full'
            px='4'
        >
            <Box
                rounded='xl'
                overflow="hidden"
                w={{
                    base: 'full',
                    lg: '50%',
                }}
                h='80%'
            >
                {image &&
                <WAMPImage
                    src={image?.xl}
                    image={image}
                    fill
                    alt='welcome-bg'
                    contentFit='cover'
                />
                }

                <Box style={StyleSheet.absoluteFillObject} bg="rgba(0, 0, 0, 0.20)"/>

                <Column
                    alignItems='center'
                    flex={1}
                    p='4'
                    style={StyleSheet.absoluteFillObject} position='absolute'
                >
                    <Center flex={1}>
                        <IcWAMP width={55} height={30} />
                    </Center>

                    <Center flex={2}>
                        <Text
                            variant='strongH1'
                            color='lime.default'
                            textAlign='center'
                            px='10'
                        >
                            {text ?? t('auth.signUp.joinNow')}
                        </Text>
                    </Center>

                    <Center flex={1}>
                        <IcWAMP width={55} height={30} />
                    </Center>

                    <Column flex={1} w='full' justifyContent='flex-end'>
                        <Button variant='lime' w='full' onPress={onOpenRegister}>
                            {t('auth.signUp.register')}
                        </Button>

                        <TouchableOpacity onPress={onOpenLogin} style={touchableStyle}>
                            <Row w='full' justifyContent='center' py='4' space={1}>
                                <Text variant='body' color='white'>
                                    {t('auth.signUp.alreadyMember')}
                                </Text>

                                <Text variant='body' color='white' fontWeight='600'>
                                    {t('auth.signIn.signIn')}
                                </Text>
                            </Row>
                        </TouchableOpacity>
                    </Column>
                </Column>

            </Box>
        </Center>
    );
};

const touchableStyle = {
    width: '100%',
};

export default LoginScreen;
