import {Button, IInputProps, Row, Text} from 'native-base';
import {MAX_HCP, MIN_HCP} from '@ui/helpers/constants';
import React, {useState} from 'react';
import {curryRight, toNumber} from 'lodash';
import AuthWrapperColumn from '@ui/features/auth/components/AuthWrapperColumn';
import BottomSheetTextInput from '@ui/components/sheet/BottomSheetTextInput';
import {IHStackProps} from 'native-base/lib/typescript/components/primitives/Stack/HStack';
import IcPlus from '@ui/svg/IcPlus';
import {Keyboard} from 'react-native';
import SaveButton from '@ui/components/buttons/save/SaveButton';
import {UpdateHcpRequest} from '@shared/models/user';
import {removeNonNumbers} from '@shared/helpers/string';
import {roundDigital} from '@ui/helpers/input';
import useCallable from '@ui/hooks/useCallable';
import useCrouton from '@ui/hooks/useCrouton';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useUser} from '@ui/provider/user/UserProvider';

const replaceComma = (value: string) => value.replace(',', '.');
const removeSecondDot = (value: string) => value.split('.').filter((v, i) => i < 2).join('.');
const roundSingleDigit = curryRight(roundDigital)(1);

interface Props {
    onFinish: () => void;
    isEdit?: boolean;
}

const AuthHcpScreen = (props: Props) => {
    const {onFinish, isEdit = false} = props;
    const {user} = useUser();
    const {t} = useTranslation();
    const crouton = useCrouton();
    const [updateHcp, updateHcpLoading] = useCallable<UpdateHcpRequest, unknown>('user-updateHcp');

    const [hcp, setHcp] = useState('');
    const [negative, setNegative] = useState(false);

    const isButtonDisabled = hcp.length === 0;

    useEffect(() => {
        const userHcp = user?.sports?.golf?.hcp;

        if(hcp !== '' && userHcp !== undefined) {
            setHcp(userHcp.toString().replace('-', ''));
            setNegative(userHcp < 0);
        }
    }, [user]);

    const onPress = async() => {
        if(isButtonDisabled || !user) {
            return;
        }

        Keyboard.dismiss();

        const number = toNumber(hcp);
        const hcpValue = negative ? -number : number;
        if(hcpValue < MIN_HCP) {
            crouton.show({
                title: t('actions.warning'),
                message: t('auth.hcp.invalid'),
                preset: 'error',
            });
            return;
        }

        if(hcp !== '') {
            const response = await updateHcp({hcp: hcpValue, uid: user.uid});
            if(!response) {
                return;
            }
        }

        onFinish();
    };

    return (
        <AuthWrapperColumn
            w='full'
            h='full'
            padding="4"
            rounded="xl"
            flex={1}
        >
            <Text variant='h5'>{t('auth.hcp.title')}</Text>

            <HpcInputField
                hcp={hcp}
                setValue={setHcp}
                onPress={onPress}
                negative={negative}
                setNegative={setNegative}
            />

            <SaveButton isDisabled={isButtonDisabled} onPress={onPress} isLoading={updateHcpLoading} isNext={!isEdit} mb='4'/>
        </AuthWrapperColumn>
    );
};

export default AuthHcpScreen;

interface HcpInputFieldProps extends IHStackProps {
    hcp: string;
    setValue: (v: string) => void;
    onPress?: () => void;
    negative: boolean;
    setNegative: (v: boolean) => void;
    _textInput?: IInputProps;
}

export const HpcInputField = (props: HcpInputFieldProps) =>{
    const {hcp, setValue, onPress, negative, setNegative, ...rest} = props;
    const {t} = useTranslation();

    const onChangeHcp = (v: string) => {
        const hcp = [replaceComma, removeNonNumbers, removeSecondDot, roundSingleDigit].reduce((acc, fn) => fn(acc), v);
        const number = toNumber(hcp);
        if(number > MAX_HCP) {
            setValue(MAX_HCP.toString());
        } else {
            setValue(hcp);
        }
    };


    return (
        <Row alignItems='center' flex={1} {...rest}>
            {negative && (<IcPlus />)}

            <BottomSheetTextInput
                flex={1}
                isRequired
                value={hcp}
                onChangeText={onChangeHcp}
                keyboardType='decimal-pad'
                autoCorrect={false}
                autoCapitalize="none"
                returnKeyType='next'
                onSubmitEditing={onPress}
                blurOnSubmit={false}
                variant='unstyled'
                placeholder={t('auth.hcp.placeholder')}
                fontSize={24}
                fontWeight={600}
                lineHeight={28}
                placeholderTextColor='secondary.200'
                autoFocus
                {...props._textInput}
            />

            <Button
                variant='solid'
                bg={negative ? 'lime.300' : 'secondary.100'}
                _pressed={{bg: negative ? 'lime.500' : 'secondary.300'}}
                _focus={{bg: negative ? 'lime.400' : 'secondary.200'}}
                onPress={() => setNegative(!negative)} rounded='full' h='8' w='8'
            >
                <IcPlus />
            </Button>
        </Row>
    );
};
