import {Circle, Row, Text, useDisclose} from 'native-base';
import AuthHcpModal from '@ui/features/auth/hcp/AuthHcpModal';
import DashboardCard from '@ui/features/clubhouse/components/DashboardCard';
import IcEdit from '@ui/svg/IcEdit';
import React from 'react';
import useCrouton from '@ui/hooks/useCrouton';
import {useTranslation} from 'react-i18next';
import {useUser} from '@ui/provider/user/UserProvider';

const HandicapCard = () => {
    const {isOpen, onOpen, onClose} = useDisclose();
    const {t} = useTranslation();
    const crouton = useCrouton();
    const {user} = useUser();

    const userHcp = user?.sports?.golf?.hcp;

    const onFinish = () => {
        crouton.show({
            title: t('actions.success'),
            message: t('auth.hcp.success'),
        });
        onClose();
    };


    return (
        <>
            <DashboardCard onPress={onOpen} >
                <Row
                    justifyContent='space-between'
                    alignItems='center'
                >
                    <Text color='blacks.700' variant='mediumBody'>
                        {t('clubhouse.handicap')}
                    </Text>

                    <Circle bgColor='light.100' p={1} m={-2}>
                        <IcEdit />
                    </Circle>
                </Row>

                <Text
                    variant='h4'
                >
                    {userHcp ? userHcp : '-'}
                </Text>
            </DashboardCard>

            <AuthHcpModal
                isOpen={isOpen}
                onClose={onClose}
                onFinish={onFinish}
            />

        </>
    );
};

export default HandicapCard;
