import React, {memo} from 'react';
import {ImageUris} from '@shared/models/image';
import LoginModal from '@ui/features/auth/login/LoginModal';
import LoginScreen from '@ui/features/auth/login/LoginScreen';
import {delay} from 'lodash';
import {useDisclose} from 'native-base';

interface Props {
    image?: ImageUris;
    text?: string;
}

const UserTab = (props: Props) => {
    const {isOpen: isLoginOpen, onOpen: onLoginOpen, onClose: onLoginClose} = useDisclose();

    const onCloseModals = () => {
        // Sorry for this hack, but it's the only way to make sure the modal is closed before opening another one
        delay(() => {
            onLoginClose();
        }, 200);
    };

    return (
        <>
            <LoginScreen onOpenRegister={onLoginOpen} onOpenLogin={onLoginOpen} image={props.image} text={props.text}/>

            <LoginModal
                isOpen={isLoginOpen}
                onClose={onLoginClose}
                onFinish={onCloseModals}
            />
        </>
    );
};

export default memo(UserTab);
