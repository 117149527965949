
const onChangeMinMaxValue = (value: number, min: number, max: number) => {
    return Math.min(Math.max(min, value), max);
};

const removeNonDigital = (v: string) => {
    return v.replace(/[^0-9.]/g, '');
};

const isValidNumber = (v: string) => {
    return !isNaN(Number(v));
};

const roundDigital = (v: string, decimal: number) => {
    const value = removeNonDigital(v);
    const parts = value.split('.');
    if (parts.length === 1){
        return value;
    }
    const [int, dec] = parts;
    return `${int}.${dec.substr(0, decimal)}`;
};

const onChangeNumberText = (v: string) => {
    return removeNonDigital(v);
};

export {onChangeNumberText, onChangeMinMaxValue, removeNonDigital, roundDigital, isValidNumber};
