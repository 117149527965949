import {Button} from 'native-base';
import React from 'react';
import {SaveButtonProps} from './SaveButtonProps';
import {useTranslation} from 'react-i18next';

const SaveButton = (props: SaveButtonProps) => {
    const {isNext, isSkip, ...restProps} = props;
    const {t} = useTranslation();

    return (
        <Button variant='primary' {...restProps} >{t(`actions.${isSkip ? 'skip' : isNext ? 'next' : 'save'}`)}</Button>
    );
};


export default SaveButton;
