import * as React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';

const IcPencil = (props: SvgProps) => (
    <Svg
        viewBox='0 0 17 17'
        width={17}
        height={17}
        fill={props.fill ?? '#C1F979'}
        {...props}
    >

        <Path
            d="M3.886 13.562h.824l6.173-6.173-.824-.825-6.173 6.174v.824Zm9.136-6.896-2.25-2.23.87-.869a.972.972 0 0 1 .713-.294c.28 0 .518.098.714.294l.812.812c.195.195.297.43.304.703a.925.925 0 0 1-.284.704l-.88.88Zm-.723.733-7.163 7.163h-2.25v-2.25l7.163-7.163 2.25 2.25Z"
        />
    </Svg>
);
export default IcPencil;
