import * as React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';

const IcEdit = (props: SvgProps) => (
    <Svg
        width={20}
        height={20}
        viewBox='0 0 20 20'
        fill="#151515"
        {...props}
    >
        <Path
            d="M4.295 15.833h1.03l7.717-7.716-1.03-1.03-7.717 7.716v1.03Zm11.42-8.62-2.813-2.787L13.99 3.34c.244-.245.542-.367.892-.367s.648.122.893.367l1.014 1.014c.245.245.372.538.38.88.009.342-.11.635-.354.88l-1.1 1.099Zm-.904.917-8.954 8.953H3.045v-2.812l8.953-8.954 2.813 2.813Z"
        />
    </Svg>
);

export default IcEdit;
