import {UserProvider, useUser} from '@ui/provider/user/UserProvider';
import AuthHcpScreen from './AuthHcpScreen';
import BottomsheetProps from '@ui/components/sheet/BottomsheetProps';
import BottomsheetWamp from '@ui/components/sheet/Bottomsheet';
import React from 'react';

interface ModalProps extends Omit<BottomsheetProps, 'children'> {
    onFinish: () => void;
}

const AuthHcpModal = (props: ModalProps) => {
    const userProvider = useUser();

    return (
        <BottomsheetWamp
            _bottomSheetProps={{snapPoints: ['50%']}}
            _wrapper={{minHeight: 400}}
            {...props}
        >
            <UserProvider value={userProvider}>
                <AuthHcpScreen isEdit onFinish={props.onFinish} />
            </UserProvider>
        </BottomsheetWamp>
    );
};

export default React.memo(AuthHcpModal, (prevProps, nextProps) => {
    return prevProps.isOpen === nextProps.isOpen;
});
