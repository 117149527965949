import {Box} from 'native-base';
import DashboardLayout from '@ui/layout/DashboardLayout';
import {ImageUris} from '@shared/models/image';
import {PresenceLoadingScreen} from '@ui/components/LoadingScreen';
import React from 'react';
import UserTab from '@ui/features/tabs/UserTab';
import {useUser} from '@ui/provider/user/UserProvider';

interface AuthWrapperProps extends JSX.ElementChildrenAttribute {
    image?: ImageUris;
    text?: string;
    useDashboardLayout?: boolean;
}

const AuthWrapper = (props: AuthWrapperProps) => {
    const {useDashboardLayout = true} = props;
    const {isLoggedIn, isLoading} = useUser();

    return (
        <PresenceLoadingScreen isLoading={isLoading}>
            {isLoggedIn ?
                props.children :
                useDashboardLayout ?
                    <DashboardLayout>
                        <UserTab image={props.image} text={props.text}/>
                    </DashboardLayout> :
                    <Box flex={1} >
                        <UserTab image={props.image} text={props.text}/>
                    </Box>
            }
        </PresenceLoadingScreen>
    );
};


export default AuthWrapper;
